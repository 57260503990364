import { LotPriceDetails } from "api/models/lotPriceDetails";

const LotPriceBreakdown = (props: {
  lotPriceDetails: LotPriceDetails,
  currency: string,
  bidAmount: number
}) => {
  const { lotPriceDetails, currency, bidAmount } = props;

  const platformTaxAmount = 0.01 * lotPriceDetails.platformFeePercentage * (bidAmount + lotPriceDetails.dismantlingCosts);
  const vatPlatformTaxAmount = 0.01 * lotPriceDetails.vat * platformTaxAmount;
  const vatAmount = 0.01 * lotPriceDetails.vat * (bidAmount + lotPriceDetails.dismantlingCosts);
  const totalPrice = bidAmount + platformTaxAmount + vatPlatformTaxAmount + vatAmount;

  return (
    <div className="space-y-2">
      {lotPriceDetails.dismantlingCosts &&
        <div className="flex flex-row justify-between gap-2">
          <p>Costuri demontare</p>
          <div>
            <p>{lotPriceDetails.dismantlingCosts} {currency}</p>
          </div>
        </div>}

      <div className="flex flex-row justify-between gap-2">
        <p>TVA</p>
        <div className="basis-2/5 flex flex-row justify-items-end justify-between gap-2">
          <p>{lotPriceDetails.vat}%</p>
          <p>{vatAmount.toFixed(2)} {currency}</p>
        </div>
      </div>

      <div className="flex flex-row justify-between gap-2">
        <p>Comision platformă</p>
        <div className="basis-2/5 flex flex-row justify-items-end justify-between gap-2">
          <p>{lotPriceDetails.platformFeePercentage}%</p>
          <p>{platformTaxAmount.toFixed(2)} {currency}</p>
        </div>
      </div>

      <div className="flex flex-row justify-between gap-2">
        <p>TVA comision platformă</p>
        <div className="basis-2/5 flex flex-row justify-items-end justify-between gap-2">
          <p>{lotPriceDetails.vat}%</p>
          <p>{vatPlatformTaxAmount.toFixed(2)} {currency}</p>
        </div>
      </div>

      <div className="flex flex-row justify-between gap-2 font-semibold">
        <p>Total</p>
        <div>
          <p>{totalPrice.toFixed(2)} {currency}</p>
        </div>
      </div>
    </div>
  )
};

export default LotPriceBreakdown;