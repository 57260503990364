/* eslint-disable react/style-prop-object */
import { Tabs } from 'flowbite-react';
import { useAppSelector } from "store";
import CompanyData from "./profile/CompanyData";
import PasswordChange from "./profile/PasswordChange";
import PersonalData from "./profile/PersonalData";

export default function UserProfile() {
  const user = useAppSelector((state) => state.currentUser);
  const countries = useAppSelector((state) => state.staticData.countries);

  return (
    <div className="w-full">
      <Tabs aria-label="Profil" style="underline">
        {user.status === "Approved" && <Tabs.Item active title="Date personale">
          <div className="py-2">
            <PersonalData user={user} />
          </div>
        </Tabs.Item>}
        {user.status === "Approved" && <Tabs.Item active title="Date companie">
          <div className="py-2">
            <CompanyData user={user} countries={countries} />
          </div>
        </Tabs.Item>}
        <Tabs.Item title="Schimbare parolă">
          <div className="py-2">
            <PasswordChange />
          </div>
        </Tabs.Item>
      </Tabs>
    </div>
  );
}
